.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

body {
  height: 100vh;
  overflow-y: scroll;
}
body::-webkit-scrollbar{
  width: 12px;
}
body::-webkit-scrollbar-track{
  background: rgb(42,42,42);
}
::-webkit-scrollbar-thumb{
  width: 14px;
  background: rgb(112,112,112)
}