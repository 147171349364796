.Home {
    overflow: hidden;
}
.Speed {
    background-image: url('../../images/scroll.webp');
    background-repeat: repeat-x;
    height: 334px;
    width: 29448px;
    animation: slidein 100s linear infinite;
    
}

.Slow {
    background-image: url('../../images/scroll.webp');
    background-repeat: repeat-x;
    height: 334px;
    width: 29448px;
    animation: slidein 140s linear infinite;
}

@keyframes slidein {
    0% {
        transform: translateX(-1056px);
    }
    100% {
        transform: translateX(-15780px);
    }
}

