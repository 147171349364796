.footer {
    position: fixed;
    padding: 0px 10px 0px 10px;
    bottom: 0;
    width: 100%;
    height: 35px;
    clear: both;
    overflow: auto;
    font-size: calc(90%);
    color: hsl(0, 19%, 85%);
    background-color: rgb(43, 41, 41);
  }

